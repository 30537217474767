























































import GET_DISCOUNTS from "@/modules/discounts/graphql/GetDiscounts.gql";
import Table from "../components/Table.vue";
import EmptyDiscount from "../components/Empty.vue";
import { defineComponent, ref, onMounted, watch } from "@vue/composition-api";
import { useDebounceValue } from "@/composable";
import { Input, Anchor, LoadingZone, Paginator } from "@/design-system";

export default defineComponent({
  name: "Discounts",
  components: {
    Table,
    EmptyDiscount,
    Input,
    Anchor,
    LoadingZone,
    Paginator,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const query = ref<string>("");
    const discounts = ref<[]>([]);
    const pagination = ref<Record<string, any>>({});
    const isLoading = ref<boolean>(false);
    const currentPage = ref<number>(1);
    const itemsPerPage = ref<number>(20);
    const discountsExist = ref<boolean>(false);

    const fetchDiscounts = async () => {
      isLoading.value = true;

      const { data } = await context.root.$apollo.query({
        query: GET_DISCOUNTS,
        variables: {
          perPage: itemsPerPage.value,
          page: currentPage.value,
          search: query.value,
          businessId: props.businessId,
        },
      });

      discounts.value = data.discounts.data ?? [];
      pagination.value = data.discounts.paginatorInfo ?? {};

      isLoading.value = false;
    };

    onMounted(async () => {
      await fetchDiscounts();
      discountsExist.value = discounts.value.length > 0;
    });

    const { debouncedValue } = useDebounceValue(query, 500);
    watch(debouncedValue, () => fetchDiscounts());

    watch(
      () => props.businessId,
      async () => {
        await fetchDiscounts();
        discountsExist.value = discounts.value.length > 0;
      }
    );

    const goToPage = (page: number) => {
      currentPage.value = page;
      fetchDiscounts();
    };

    return {
      // state
      query,
      isLoading,
      discounts,
      pagination,
      discountsExist,

      // actions
      goToPage,
    };
  },
});
