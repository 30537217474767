import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum, RepoErrorDiscountEnum } from "../repo-error.enum";
import { graphqlExtractValidationErrors } from "@/v2/util/graphql-extract-validation-errors";
import { repoCommonValidateDiscountRequest } from "../repo-common";
import { FetchResult } from "@apollo/client";
import {
  DiscountVisibilityEmum,
  DiscountSegmentationEnum,
  DiscountTypeEnum,
} from "@/v2/enum/discount";
import { OrderingServiceEnum } from "@/v2/enum";

export async function updateDiscount(
  request: IUpdateDiscountRequest
): Promise<[IUpdateDiscountResponse, IUpdateDiscountErrors]> {
  // Sanatize
  const sanatizedRequest = repoCommonValidateDiscountRequest(request);

  try {
    const response = await graphql.mutate({
      mutation,
      variables: sanatizedRequest,
    });

    return [response.data, processErrors(response)];
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

type IUpdateDiscountErrors = {
  code?: RepoErrorDiscountEnum;
};

function processErrors(
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): IUpdateDiscountErrors {
  const validationErrors = graphqlExtractValidationErrors(
    response?.errors?.[0]
  );
  const processedErrors: IUpdateDiscountErrors = {};

  if (validationErrors["code"] === "The code has already been taken.") {
    processedErrors.code = RepoErrorDiscountEnum.DISCOUNT_CODE_DUPLICATE;
  }

  return processedErrors;
}

interface IUpdateDiscountResponse {
  editDiscount: boolean | null;
}

export interface IUpdateDiscountRequest {
  businessId: string;
  discountId: string;
  name: string;
  code: string;
  description: String;
  visibility: DiscountVisibilityEmum;
  type: DiscountTypeEnum;
  amount?: number;
  minimumOrderValue?: number;
  maximumDiscount?: number;
  segmentation: DiscountSegmentationEnum;
  usesPerCustomer?: number;
  startsAt: string;
  endsAt: string | null;
  totalUses?: number;
  items: Array<string>;
  orderingServices: Array<OrderingServiceEnum>;
}
