<template>
  <div class="empty-status">
    <Icon icon="discount-gray" :size="100" fill="#a6bbc8" />
    <h5 class="mt-4 mb-3">
      {{ $t("c.discounts.empty_title") }}
    </h5>
    <!-- eslint-disable -->
    <div class="empty-status__subtitle" v-html="$t('c.discounts.empty_subtitle')"></div>
    <!-- eslint-enable -->
    <Anchor
      v-if="isOutletAvailable"
      button
      primary
      class="text-nowrap"
      :link="{
        name: 'discounts.create',
        params: { businessId: businessId },
      }"
    >
      {{ $t("c.discounts.create_a_discount") }}
    </Anchor>
    <div v-else class="empty-status__wrapper">
      <div class="empty-status__subtitle">
        {{ $t("c.discounts.empty_outlet") }}
      </div>
      <Anchor
        button
        primary
        class="text-nowrap"
        :link="{
          name: 'outlets.create',
          params: { businessId: businessId },
        }"
      >
        {{ $t("c.outlets.add_new_outlet") }}
      </Anchor>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Icon, Anchor } from "@/design-system";

export default {
  name: "Empty",
  components: {
    Icon,
    Anchor,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      outlets: "outlets/getData",
    }),
    isOutletAvailable() {
      return Array.isArray(this.outlets)
        ? this.outlets.some((outlet) => outlet.businessId === this.businessId)
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-status {
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
  padding: 3rem;

  &__wrapper {
    max-width: 300px;
    margin: 40px auto;
    padding-top: 40px;
    border-top: solid 1px #dee2e6;
  }

  &__subtitle {
    color: #89a0b1;
    margin-bottom: 1.5rem;

    ::v-deep a {
      color: #89a0b1;
      text-decoration: underline;
    }
  }
}
</style>
