<template>
  <div
    class="discount-item"
    data-test="line"
    :class="{ 'discount-item--is-disabled': !discount.status }"
  >
    <div class="discount-item__corner">
      <ChangeStatus
        :business-id="businessId"
        :discount-id="discount.id"
        :status="discount.status"
        @updated="updateStatus"
      />
    </div>
    <router-link
      :to="{
        name: 'discounts.edit',
        params: { businessId: businessId, discountId: discount.id },
      }"
      class="discount-item__link"
    >
      <div>
        <strong class="discount-item__code">
          {{ discount.code }}
          <CopyCode
            v-if="canCopyCode"
            class="discount-item__copy-code"
            :discount-default-type="copyCodeDiscountType.toLowerCase()"
            :code="discount.code"
          />
        </strong>
        <p class="m-0">{{ discount.name }}</p>
      </div>
      <div>
        {{
          $t(
            `c.discounts.visibility_${discount.visibility.toLowerCase()}_title`
          )
        }}
      </div>
      <div class="text-center">
        <!-- eslint-disable -->
        <i18n v-if="isPercentage" data-test="percentage" path="c.discounts.x_percentage_off">{{discount.amount}}</i18n>
        <!-- eslint-enable -->
        <i18n
          v-if="isFixedValue"
          data-test="fixed-value"
          path="c.discounts.x_fixed_value_off"
        >
          <Money
            tag="span"
            :amount="discount.amount"
            :business-id="businessId"
          />
        </i18n>
        <i18n
          v-if="discount.itemsCount"
          class="discount-item__selected_items_text"
          path="c.discounts.applies_selected_items"
        />
      </div>
      <div class="text-right">
        <Money
          v-if="discount.minimumOrderValue > 0"
          data-test="min-basket"
          tag="span"
          :amount="discount.minimumOrderValue"
          :business-id="businessId"
        />
      </div>
      <div class="text-right">
        <Money
          v-if="discount.maximumDiscount > 0"
          data-test="max-discount"
          tag="span"
          :amount="discount.maximumDiscount"
          :business-id="businessId"
        />
      </div>
      <div class="text-center" data-test="availability">
        <!-- eslint-disable -->
        {{ discount.startsAt | date("MMM DD, YYYY") }}<span v-if="discount.endsAt"> – {{ discount.endsAt | date("MMM DD, YYYY") }}</span><span v-else> – {{ $t("c.discounts.discount_no_expiration_label") }}</span>
        <!-- eslint-enable -->
      </div>
      <div class="text-right">
        <Money
          data-test="total-sales"
          tag="span"
          :amount="discount.totalSales"
          :business-id="businessId"
        />
      </div>
      <div class="text-center">{{ discount.totalUsage }}</div>
    </router-link>
    <div class="discount-item__corner">
      <Remove
        :business-id="businessId"
        :discount-id="discount.id"
        :discount-name="discount.name"
        @removed="$emit('removed', discount.id)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import ChangeStatus from "./ChangeStatus.vue";
import CopyCode from "./CopyCode.vue";
import Remove from "./Remove.vue";
import Money from "@/components/helpers/Money.vue";
import { type } from "@/utils/enums/discount";
import { date } from "@/utils/filters";
import { OrderingServiceEnum } from "@/v2/enum";

export default {
  name: "TableLine",
  filters: {
    date,
  },
  components: {
    ChangeStatus,
    CopyCode,
    Remove,
    Money,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    discount: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isPercentage() {
      return this.discount.type === type.PERCENTAGE;
    },
    isFixedValue() {
      return this.discount.type === type.FIXED_VALUE;
    },
    canCopyCode() {
      if (this.copyCodeDiscountType === OrderingServiceEnum.DINE_IN) {
        return false;
      }

      return this.discount.outlets?.length === 0;
    },
    copyCodeDiscountType() {
      const orderingServicesCount = this.discount.orderingServices?.length;
      const orderingServices = this.discount.orderingServices;

      if (orderingServicesCount === 0) {
        return "";
      }

      if (orderingServicesCount === 1) {
        return orderingServices[0];
      }

      if (
        orderingServices &&
        orderingServices.includes(OrderingServiceEnum.DELIVERY)
      ) {
        return OrderingServiceEnum.DELIVERY;
      }

      return OrderingServiceEnum.PICKUP;
    },
  },
  methods: {
    updateStatus(val) {
      this.discount.status = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-item {
  display: grid;
  min-height: 60px;
  grid-row: 1;
  grid-template-columns: 100px calc(100% - 180px) 80px;
  border-top: 1px solid #dee2e6;
  background: #fff;
  transition: all 0.2s ease-out;

  &__corner {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
  }

  &__code {
    position: relative;
  }

  &__copy-code {
    position: absolute;
    top: -7px;
    right: -10px;
    padding: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }

  &__selected_items_text {
    display: block;
    font-size: var(--font-xs);
  }

  &:hover {
    background: #f4f6f9;

    .discount-item__copy-code {
      right: -20px;
      opacity: 1;
      visibility: visible;
    }
  }

  &__link {
    display: grid;
    align-items: center;
    grid-template-columns:
      minmax(150px, 1.5fr) minmax(140px, 1fr) minmax(120px, 1fr)
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(140px, 2fr)
      minmax(120px, 1fr) minmax(100px, 1fr);

    &:hover,
    &:focus {
      text-decoration: none;
    }

    > div {
      line-height: 130%;
      color: #292929;
      padding: 15px 10px;
    }
  }

  &--is-disabled .discount-item {
    &__link > div {
      color: #dee2e6;
    }

    &__copy-code {
      display: none;
    }
  }
}
</style>
