<template>
  <div class="table-discounts">
    <div class="table-discounts__head">
      <div></div>
      <div>{{ $t("c.discounts.discount") }}</div>
      <div>{{ $t("c.discounts.visibility") }}</div>
      <div class="text-center">{{ $t("c.discounts.type") }}</div>
      <div class="text-right">{{ $t("c.discounts.min_basket") }}</div>
      <div class="text-right">{{ $t("c.discounts.max_discount") }}</div>
      <div class="text-center">{{ $t("c.discounts.availability") }}</div>
      <div class="text-right">{{ $t("c.discounts.total_sales") }}</div>
      <div class="text-center">{{ $t("c.discounts.total_usage") }}</div>
      <div></div>
    </div>
    <div class="table-discounts__body">
      <TableLine
        v-for="discount in discounts"
        :key="discount.id"
        :discount="discount"
        :business-id="businessId"
        @removed="discountDeleted"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import TableLine from "../components/TableLine.vue";
import { indexOf, find } from "ramda";

export default {
  name: "Table",
  components: {
    TableLine,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    discounts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    discountDeleted(discountId) {
      const index = indexOf(
        find((obj) => obj.id === discountId, this.discounts),
        this.discounts
      );
      this.discounts.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-discounts {
  display: grid;
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid #dee2e6;

  &__head {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(1, 60px);
    grid-template-columns:
      100px minmax(150px, 1.5fr) minmax(140px, 1fr) minmax(120px, 1fr)
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(140px, 2fr)
      minmax(120px, 1fr) minmax(100px, 1fr) 80px;
    font-weight: 600;

    > div {
      display: grid;
      align-items: center;
      height: 60px;
      padding: 10px;
      line-height: 130%;
      color: #292929;
    }
  }
}
</style>
