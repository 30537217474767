import Enumeration from "./enumeration";

/**
 * List of visibility
 * @returns {Enumeration<statuses>}
 */
export const visibility = new Enumeration({
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
  HIDDEN: "HIDDEN",
});

/**
 * List of type
 * @returns {Enumeration<statuses>}
 */
export const type = new Enumeration({
  PERCENTAGE: "PERCENTAGE",
  FIXED_VALUE: "FIXED_VALUE",
});

/**
 * List of segmentation
 * @returns {Enumeration<statuses>}
 */
export const segmentation = new Enumeration({
  ALL: "ALL",
  FIRST_ORDERS: "FIRST_ORDERS",
});
