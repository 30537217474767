<template>
  <a
    v-tooltip="{
      autoHide: false,
      trigger: 'click hover',
      content: `${$t('label.share_discount')}`,
    }"
    href="#"
    data-test="copy"
    @click.stop="copyCode"
  >
    <Icon icon="link" :size="11" color="#292929" />
    <input ref="link" type="hidden" />
  </a>
</template>

<script>
import { mapGetters } from "vuex";
import { find } from "ramda";
import { string } from "@/v2/util/prop/type";
import { isOneOf } from "@/v2/util/prop/validator";
import { Icon, Toast } from "@/design-system";

export default {
  name: "CopyCode",
  components: {
    Icon,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    discountDefaultType: string()
      .isRequired()
      .setValidator(isOneOf("delivery", "pickup")),
  },
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    activeBusiness() {
      return (
        find(
          (obj) => obj.id === this.$route.params.businessId,
          this.businesses
        ) || {}
      );
    },
    discountLink() {
      return `${process.env.VUE_APP_WEBVIEW_URL}/${this.activeBusiness.url}/${this.discountDefaultType}?v=${this.code}`;
    },
  },
  methods: {
    copyCode() {
      if (!window.navigator.clipboard) {
        this.fallbackCopyCode();
        return;
      }

      try {
        window.navigator.clipboard.writeText(this.discountLink);

        new Toast().create({
          type: "success",
          text: this.$t("message.link_copied"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      }
    },
    fallbackCopyCode() {
      try {
        const linkToCopy = this.$refs.link;
        linkToCopy.setAttribute("type", "text");
        linkToCopy.value = this.discountLink;
        linkToCopy.select();

        document.execCommand("copy");
        new Toast().create({
          type: "success",
          text: this.$t("message.link_copied"),
        });

        linkToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      }
    },
  },
};
</script>
