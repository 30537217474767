
















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch } from "@vue/composition-api";
import { fetchOutlets } from "@/v2/repo/fetch-outlets";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { Radio } from "@/design-system";
import { AtomCheckbox } from "@/v2/design-system";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolLoadingZone,
} from "@/v2/new-design-system";
import { DiscountVisibilityEmum } from "@/v2/enum/discount";

const css = bemBuilder("select-outlets");

interface IOutlets {
  name: string;
  id: string;
}

export default defineComponent({
  name: "SelectOutlets",
  components: {
    AtomText,
    Radio,
    AtomCheckbox,
    MolLoadingZone,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    selectedOutlets: {
      type: Array,
      default: () => [],
    },
    visibility: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasSelectOutlets = ref(false);
    const isLoading = ref(false);
    const outlets = ref<IOutlets[]>([]);
    const selectedOutletsSet = ref(new Set(props.selectedOutlets));

    if (props.selectedOutlets.length > 0) {
      hasSelectOutlets.value = true;
      getOutlets();
    }

    watch(
      () => hasSelectOutlets.value,
      (value) => {
        if (hasSelectOutlets.value) {
          getOutlets();
          return;
        }
        selectedOutletsSet.value = new Set([]);
      }
    );

    watch(
      () => selectedOutletsSet.value,
      (value) => {
        emit("update", Array.from(selectedOutletsSet.value));
      }
    );

    async function getOutlets() {
      isLoading.value = true;

      try {
        const res = await fetchOutlets({
          businessId: props.businessId,
          perPage: 999,
          page: 1,
        });
        outlets.value = res.data;
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function selectOutlet(outletId: string): void {
      if (selectedOutletsSet.value.has(outletId)) {
        selectedOutletsSet.value.delete(outletId);
      } else {
        selectedOutletsSet.value.add(outletId);
      }

      selectedOutletsSet.value = new Set(selectedOutletsSet.value);
    }

    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      hasSelectOutlets,
      isLoading,
      outlets,
      selectedOutletsSet,
      selectOutlet,
      DiscountVisibilityEmum,
    };
  },
});
