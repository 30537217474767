import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum, RepoErrorDiscountEnum } from "../repo-error.enum";
import { graphqlExtractValidationErrors } from "@/v2/util/graphql-extract-validation-errors";
import { repoCommonValidateDiscountRequest } from "../repo-common";
import { FetchResult } from "@apollo/client";
import {
  DiscountVisibilityEmum,
  DiscountTypeEnum,
  DiscountSegmentationEnum,
} from "@/v2/enum/discount";

export async function createDiscount(
  request: ICreateDiscountRequest
): Promise<[ICreateDiscountResponse, ICreateDiscountErrors]> {
  try {
    // Sanatize
    const sanatizedRequest = repoCommonValidateDiscountRequest(request);

    const response = await graphql.mutate({
      mutation,
      variables: sanatizedRequest,
    });

    return [response.data, processErrors(response)];
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

type ICreateDiscountErrors = {
  code?: RepoErrorDiscountEnum;
};

function processErrors(
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): ICreateDiscountErrors {
  const validationErrors = graphqlExtractValidationErrors(
    response?.errors?.[0]
  );
  const processedErrors: ICreateDiscountErrors = {};

  if (validationErrors["code"] === "The code has already been taken.") {
    processedErrors.code = RepoErrorDiscountEnum.DISCOUNT_CODE_DUPLICATE;
  }

  return processedErrors;
}

interface ICreateDiscountResponse {
  createDiscount: {
    id: string;
  };
}

export interface ICreateDiscountRequest {
  businessId: string;
  name: string;
  code: string;
  description: String;
  visibility: DiscountVisibilityEmum;
  type: DiscountTypeEnum;
  amount: number;
  minimumOrderValue?: number;
  maximumDiscount?: number;
  segmentation: DiscountSegmentationEnum;
  usesPerCustomer?: number;
  startsAt: string;
  endsAt: string | null;
  totalUses: number;
}
