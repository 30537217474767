import Preview from "@/modules/discounts/ui/components/Preview.vue";
import SelectOutlets from "@/modules/discounts/ui/components/SelectOutlets.vue";
import dayjs from "dayjs";
//@ts-ignore
import Defaults from "@/utils/enums/defaults";
import { toLocaleCurrency } from "@/v2/util/to-locale-currency";
//@ts-ignore
import { visibility, type, segmentation } from "@/utils/enums/discount";
import {
  LoadingZone,
  FormControl,
  FormList,
  Input,
  InputMoney,
  SelectTime,
  DatePicker,
  Radio,
  Button,
  Toast,
  //@ts-ignore
} from "@ds";

import { fetchDiscount } from "@/v2/repo/fetch-discount";
import { updateDiscount } from "@/v2/repo/update-discount";
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { discountTransformFetchDiscount } from "../transform/fetch-discount";
import { t, tc } from "@/i18n";
import { string } from "@/v2/util/prop/type";
import { createDiscount } from "@/v2/repo/create-discount";
import { isEmptyObject } from "@/v2/util/is-empty-object";
import { RepoErrorDiscountEnum } from "@/v2/repo/repo-error.enum";
import {
  fetchItemsCatalogued,
  IFetchItemsCataloguedResponse,
} from "@/v2/repo/fetch-items-catalogued";
import { replaceReactiveObject } from "@/v2/util/replace-reactive-object";
import {
  DiscountVisibilityEmum,
  DiscountTypeEnum,
  DiscountSegmentationEnum,
} from "@/v2/enum/discount";
import {
  OrderingServiceEnum,
  DateFormatEnum,
  CurrencyCodeEnum,
} from "@/v2/enum";
import { AtomIcon, AtomCheckbox, MolSearchBox } from "@/v2/design-system";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system/atom/text";
import { OrgSelectList, AtomMoney } from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";

export default defineComponent({
  name: "DiscountForm",
  components: {
    LoadingZone,
    FormControl,
    FormList,
    Input,
    InputMoney,
    AtomMoney,
    Radio,
    SelectTime,
    DatePicker,
    Button,
    Preview,
    SelectOutlets,
    AtomIcon,
    AtomText,
    AtomCheckbox,
    MolSearchBox,
    OrgSelectList,
  },
  props: {
    businessId: string().isRequired().use(),
    discountId: string().use(),
  },
  setup(props, ctx) {
    // Initalize State
    const formState = reactive({
      hasValidity: true,
      loadingButton: false,
      isLoading: false,
      isLoadingMenu: false,
      itemSelection: false,
      menuSearchQuery: "",
      // TODO: Eliminate use of "Enumeration" class
      visibilities: visibility.values(),
      types: type.values(),
      segmentations: segmentation.values(),
      errors: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        name: null,
        code: null,
        description: null,
        visibility: null,
        type: null,
        amount: null,
        minimumOrderValue: null,
        maximumDiscount: null,
        segmentation: null,
        usesPerCustomer: null,
        totalUses: null,
        orderTypes: null,
        items: null,
        outlets: null,
      } as Record<keyof typeof formData, string | null>,
    });

    const menuData = ref<Array<IFetchItemsCataloguedResponse>>([]);

    // Initalize Data State
    const formData = reactive({
      startDate: dayjs().format(DateFormatEnum.DATE_FORMAT),
      startTime: "00:00",
      endDate: dayjs().add(2, "week").format(DateFormatEnum.DATE_FORMAT),
      endTime: "23:59",
      name: "",
      code: "",
      visibility: DiscountVisibilityEmum.AUTOMATIC,
      type: DiscountTypeEnum.PERCENTAGE,
      amount: 0,
      minimumOrderValue: 0,
      maximumDiscount: 0,
      segmentation: DiscountSegmentationEnum.ALL,
      usesPerCustomer: 0 as string | number,
      totalUses: 0 as string | number,
      orderTypes: new Set<OrderingServiceEnum>(),
      items: [] as Array<string>,
      outlets: [] as Array<string>,
    });

    // Lifecycle hooks
    onMounted(async () => {
      try {
        if (!isCreation.value) {
          formState.isLoading = true;
          const discount = await fetchDiscount(
            props.businessId,
            props.discountId
          );

          replaceReactiveObject(
            formData,
            // @ts-ignore
            discountTransformFetchDiscount(discount, business?.value?.timezone)
          );

          if (formData.outlets.length) {
            formData.outlets = formData.outlets.map((outlet: any) => outlet.id);
          }

          formState.isLoading = false;
        }
      } catch (error) {
        report(error);
      }

      try {
        if (formData.items.length) {
          await loadMenu();
          formState.itemSelection = true;
        }
      } catch (error) {
        report(error);
      }

      if (formData.orderTypes.size === 0) {
        setOrderType(OrderingServiceEnum.DELIVERY);
        setOrderType(OrderingServiceEnum.PICKUP);
      }

      if (!formData.endDate) {
        formState.hasValidity = false;
      }
    });

    // Computed Properties
    const hasValidity = computed({
      get: () => formState.hasValidity,
      set: (val) => {
        formState.hasValidity = val;
        populateEndDate();
      },
    });

    const business = computed(() => {
      const businesses: Array<{ id: string }> = (ctx.root as any).$store
        .getters["businesses/getData"];

      if (businesses) {
        return businesses.find((item) => item.id === props.businessId);
      }

      return {};
    });

    const usesPerCustomer = computed({
      get: () => formData.usesPerCustomer,
      set: (val) => {
        formData.usesPerCustomer = val === 0 ? "" : val;
      },
    });

    const totalUses = computed({
      get: () => formData.totalUses,
      set: (val) => (formData.totalUses = val === 0 ? "" : val),
    });

    const itemSelection = computed({
      get: () => formState.itemSelection,
      set: async (isItemSelection) => {
        formState.itemSelection = isItemSelection;
        formState.menuSearchQuery = "";

        if (!menuData.value.length && formState.itemSelection) {
          await loadMenu();
        }
      },
    });

    const businessCurrencyCode = computed<CurrencyCodeEnum>(
      () => (business.value as any).currency?.code ?? Defaults.CURRENCY
    );
    const businessLocale = computed(
      () => (business.value as any).language ?? Defaults.LANGUAGE
    );
    const isCreation = computed<boolean>(() => !props.discountId);
    const isLoading = computed(() => !isCreation.value); // Shift to using pure state
    const pageTitle = computed<string>(() =>
      isCreation.value
        ? t("c.discounts.create_a_discount")
        : t("c.discounts.edit_discount")
    );

    const isPercentage = computed<boolean>(
      () => formData.type === DiscountTypeEnum.PERCENTAGE
    );
    const isFixedValue = computed<boolean>(
      () => formData.type === DiscountTypeEnum.FIXED_VALUE
    );
    const titleCard = computed<string>(
      () => formData.name || t("c.discounts.discount_name_placeholder_card")
    );

    const formattedDiscount = computed(() => {
      return isFixedValue.value
        ? formatMoney(formData.amount)
        : `${formData.amount}%`;
    });

    const messageForFirstOrders = computed<string>(() =>
      tc(
        "c.discounts.card_description_for_first_orders",
        formData.usesPerCustomer,
        {
          selected_items: formState.itemSelection
            ? t("c.discounts.certain_products")
            : "",
          discount: formattedDiscount.value,
          uses: formData.usesPerCustomer,
        }
      )
    );

    const messageForAll = computed<string>(() =>
      tc(
        "c.discounts.card_description_for_all_orders",
        formData.usesPerCustomer,
        {
          selected_items: formState.itemSelection
            ? t("c.discounts.certain_products")
            : "",
          discount: formattedDiscount.value,
          uses: formData.usesPerCustomer,
        }
      )
    );

    const descriptionCard = computed<string>(() => {
      let description = "";

      if (!formData.usesPerCustomer) {
        description = t("c.discounts.card_description_for_unlimited_orders", {
          selected_items: formState.itemSelection
            ? t("c.discounts.certain_products")
            : "",
          discount: formattedDiscount.value,
        });
      } else {
        description =
          formData.segmentation === segmentation.ALL
            ? messageForAll.value
            : messageForFirstOrders.value;
      }

      return description;
    });

    const menuNoItemsMessage = computed((): string => {
      const isNoSearchResult =
        formState.menuSearchQuery && menuData.value.length;

      if (isNoSearchResult) {
        return t("c.discounts.no_items_search");
      }
      return t("c.discounts.no_items_menu");
    });

    // Methods
    function typeHasChanged(val: DiscountTypeEnum): void {
      formData.amount = 0;

      if (val === DiscountTypeEnum.FIXED_VALUE) {
        formData.maximumDiscount = 0;
      }
    }

    function generateCode(): void {
      let code = "";
      const characters = "ABCDEFGHJKMNPQRSTUVWXYZ123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < 7; i++) {
        code += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      formData.code = code;
    }

    function formatMoney(val: number): string {
      return toLocaleCurrency(
        val,
        businessCurrencyCode.value,
        "$0,0",
        businessLocale.value
      );
    }

    function setOrderType(val: OrderingServiceEnum): void {
      formData.orderTypes.add(val);
      formData.orderTypes = new Set(formData.orderTypes);
    }

    function unsetOrderType(val: OrderingServiceEnum): void {
      if (formData.orderTypes.size === 1) {
        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("c.discounts.order_type_required"),
        });

        return;
      }

      formData.orderTypes.delete(val);
      formData.orderTypes = new Set(formData.orderTypes);
    }

    async function loadMenu(): Promise<void> {
      formState.isLoadingMenu = true;
      const menu = await fetchItemsCatalogued(props.businessId);
      menuData.value = menu || [];
      formState.isLoadingMenu = false;
    }

    function validateForm(): void {
      const unitTimeStampStartDate = new Date(
        `${formData.startDate} ${formData.startTime}:00`
      );
      const unitTimeStampEndDate = new Date(
        `${formData.endDate} ${formData.endTime}:00`
      );

      const endDateTimeIsValid = formState.hasValidity
        ? unitTimeStampStartDate < unitTimeStampEndDate
        : true;

      formState.errors = {
        ...formState.errors,
        name: formData.name ? null : t("c.discounts.name_required"),
        code: ((): string => {
          if (formData.code) {
            return formData.code.length < 4
              ? t("c.discounts.code_length")
              : null;
          }
          return t("c.discounts.code_required");
        })(),
        amount: formData.amount ? null : t("c.discounts.amount_required"),
        orderTypes:
          formData.orderTypes.size > 0
            ? null
            : t("c.discounts.order_type_required"),
        endTime: endDateTimeIsValid ? null : t("c.discounts.end_time_error"),
        usesPerCustomer:
          formData.segmentation === DiscountSegmentationEnum.FIRST_ORDERS &&
          !usesPerCustomer.value
            ? t("c.discounts.number_of_uses_required")
            : null,
      };
    }

    watch(
      () => formData.segmentation,
      (value) => {
        if (value === DiscountSegmentationEnum.ALL) {
          formState.errors.usesPerCustomer = null;
        }
      }
    );

    function scrollToError(): void {
      ctx.root.$nextTick(() => {
        Object.entries(formState.errors).some(([errorKey, value]) => {
          // TODO: accessing ctx.refs wont work in vue 3 [VUE_3_MIGRATE]
          if (value) {
            // @ts-ignore
            const element = (ctx.refs[errorKey] as any)?.$el;
            element && element.scrollIntoView();
          }

          return Boolean(value);
        });
      });
    }

    function populateEndDate(): void {
      formData.endDate = dayjs(formData.startDate)
        .add(2, "week")
        .format(DateFormatEnum.DATE_FORMAT);

      formData.endTime = "23:59";
    }

    function translateErrors(): void {
      const errorTranslationMap: Record<RepoErrorDiscountEnum, string> = {
        [RepoErrorDiscountEnum.DISCOUNT_CODE_DUPLICATE]: t(
          "c.discounts.code_duplicate"
        ),
      };

      const translatedErrors = { ...formState.errors };

      Object.entries(formState.errors).forEach(([key, value]) => {
        if (value) {
          // @ts-ignore
          const translation = errorTranslationMap[value];
          if (translation) {
            translatedErrors[key as keyof typeof formState.errors] =
              translation;
          }
        }
      });

      formState.errors = translatedErrors;
    }

    async function submitForm(): Promise<void> {
      validateForm();

      if (Object.values(formState.errors).some(Boolean)) {
        scrollToError();
        return;
      }

      formState.loadingButton = true;

      const requestPayload = {
        businessId: props.businessId,
        name: formData.name,
        code: formData.code,
        description: descriptionCard.value,
        visibility: formData.visibility,
        type: formData.type,
        amount: formData.amount,
        minimumOrderValue: formData.minimumOrderValue,
        maximumDiscount: formData.maximumDiscount,
        segmentation: formData.segmentation,
        usesPerCustomer: formData.usesPerCustomer as number,
        totalUses: formData.totalUses as number,
        startsAt: `${formData.startDate} ${formData.startTime}:00`,
        endsAt: formState.hasValidity
          ? `${formData.endDate} ${formData.endTime}:59`
          : null,
        orderingServices: Array.from(formData.orderTypes),
        items: formState.itemSelection ? formData.items : [],
        outlets: formData.outlets,
      };

      try {
        const mutationRequest = isCreation.value
          ? createDiscount(requestPayload)
          : updateDiscount({ ...requestPayload, discountId: props.discountId });

        const [, discountErrors] = await mutationRequest;

        formState.errors = { ...formState.errors, ...discountErrors };

        if (isEmptyObject(discountErrors)) {
          new Toast().create({
            type: "success",
            title: t("label.success"),
            text: isCreation
              ? t("c.discounts.discount_success_created")
              : t("c.discounts.discount_success_edited"),
          });

          ctx.root.$router.push({
            name: "discounts.index",
            params: {
              businessId: props.businessId,
            },
          });
        } else {
          new Toast().create({
            type: "error",
            title: t("label.oops"),
            text: t("c.discounts.notification_failure"),
          });

          translateErrors();
          scrollToError();
        }
      } catch (error) {
        report(error);

        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("message.general_failure"),
        });
      } finally {
        formState.loadingButton = false;
      }
    }

    return {
      AtomTextTypeEnum,
      AtomTextColorEnum,
      state: {
        formState,
        formData,
        menuData,
      },
      computed: {
        usesPerCustomer,
        totalUses,
        business,
        businessCurrencyCode,
        businessLocale,
        isCreation,
        isLoading,
        pageTitle,
        isPercentage,
        isFixedValue,
        titleCard,
        messageForFirstOrders,
        messageForAll,
        menuNoItemsMessage,
        descriptionCard,
        itemSelection,
        hasValidity,
      },
      method: {
        typeHasChanged,
        generateCode,
        submitForm,
        setOrderType,
        unsetOrderType,
      },
    };
  },
});
