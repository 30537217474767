<template>
  <Switcher v-model="isChecked" @change="update" />
</template>

<script>
import UPDATE_DISCOUNTS_STATUS from "@/modules/discounts/graphql/UpdateDiscountStatus.gql";
import { Switcher, Toast } from "@ds";
import { has } from "ramda";

export default {
  name: "ChangeStatus",
  components: {
    Switcher,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    discountId: {
      type: String,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isChecked: false,
  }),
  created() {
    this.initSwitcher();
  },
  methods: {
    initSwitcher() {
      this.isChecked = this.status;
    },
    async update() {
      // prev status to revert it back if necessary
      const staleStatus = this.status;

      // next status based on the switcher value
      const newStatus = this.isChecked;

      try {
        this.$emit("updated", newStatus);

        const res = await this.$apollo.mutate({
          mutation: UPDATE_DISCOUNTS_STATUS,
          variables: {
            businessId: this.businessId,
            discountId: this.discountId,
            isActive: newStatus,
          },
        });

        if (has("errors", res)) {
          throw res.errors;
        }

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.record_updated"),
        });
      } catch (error) {
        this.isChecked = !this.isChecked;
        this.$emit("updated", staleStatus);

        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.general_failure"),
        });
      }
    },
  },
};
</script>
