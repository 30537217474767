<template>
  <Button class="btn-custom-danger" @click.prevent="confirmDelete">
    <Icon icon="trash" :size="20" fill="#85a1b4" />
  </Button>
</template>

<script>
import REMOVE_DISCOUNT from "@/modules/discounts/graphql/RemoveDiscount.gql";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { Button, Icon, Toast } from "@ds";
import { has } from "ramda";

export default {
  name: "Remove",
  components: {
    Button,
    Icon,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    discountId: {
      type: String,
      required: true,
    },
    discountName: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirmDelete() {
      destroyDialog(this, this.remove, { resourceName: this.discountName });
    },
    async remove() {
      try {
        const res = await this.$apollo.mutate({
          mutation: REMOVE_DISCOUNT,
          variables: {
            businessId: this.businessId,
            discountId: this.discountId,
          },
        });

        if (has("errors", res)) {
          throw res.errors;
        }

        this.$emit("removed");

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.record_removed"),
        });
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.general_failure"),
        });
      } finally {
        this.$modal.hide("dialog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-custom-danger {
  display: inline-block;
  padding: 3px 5px 6px;
  border-radius: 3px;
  background: transparent;
  transition: all 0.2s ease-out;

  svg {
    transition: all 0.2s ease-out;
  }

  &:hover,
  &:focus {
    background: #e52b44;

    svg {
      fill: #fff;
    }
  }
}
</style>
