import { IRepoErrors } from "../errors";
import { http } from "../http";
import { AxiosResponse } from "axios";

export class FetchItemsCataloguedError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchItemsCatalogued");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchItemsCataloguedResponse {
  id: string;
  sku: string | null;
  name: string;
  amount: {
    value: number;
    currency: string;
  };
}

export async function fetchItemsCatalogued(
  businessId: string
): Promise<Array<IFetchItemsCataloguedResponse>> {
  let response: AxiosResponse;
  try {
    response = await http.get(
      `/api/v4/businesses/${businessId}/live-menus-items`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchItemsCataloguedError(error);
  }
  return response.data;
}
