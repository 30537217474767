import { IUpdateDiscountRequest } from "./update-discount/update-discount";
import { ICreateDiscountRequest } from "./create-discount/create-discount";

// eslint-disable-next-line
export function repoCommonValidateDiscountRequest(
  request: IUpdateDiscountRequest | ICreateDiscountRequest
) {
  return {
    ...request,
    amount: request.amount || 0,
    minimumOrderValue: request.minimumOrderValue || 0,
    maximumDiscount: request.maximumDiscount || 0,
    usesPerCustomer: request.usesPerCustomer || 0,
    totalUses: request.totalUses || 0,
    startsAt: new Date(request.startsAt).toJSON(),
    endsAt: request.endsAt ? new Date(request.endsAt).toJSON() : null,
  };
}
