/**
 * Checks if a key value pair object is empty, returns
 * `true` if empty, `false` if not empty, `null` if argument
 * was not a valid object
 *
 * @param {Record<any, any>} obj - key value pair object
 * @returns {boolean}
 */

export function isEmptyObject(obj: Record<any, any>): boolean | undefined {
  const valid = obj && typeof obj === "object";
  return valid ? !Object.keys(obj).length : undefined;
}
