import { IFetchDiscountResponse } from "@/v2/repo/fetch-discount/fetch-discount";
//@ts-ignore
import { dateTz } from "@/utils/filters";
import { OrderingServiceEnum } from "@/v2/enum";
import {
  DiscountVisibilityEmum,
  DiscountTypeEnum,
  DiscountSegmentationEnum,
} from "@/v2/enum/discount";

const DATE_FORMAT = "YYYY/MM/DD";
const HOUR_FORMAT = "HH:mm";

export function discountTransformFetchDiscount(
  response: IFetchDiscountResponse,
  businessTimezone = "Etc/GMT"
): IDiscountTransformFetchDiscountResult {
  const transformed = {} as IDiscountTransformFetchDiscountResult;

  transformed.startDate = dateTz(
    response.startsAt,
    businessTimezone,
    DATE_FORMAT
  );
  transformed.startTime = dateTz(
    response.startsAt,
    businessTimezone,
    HOUR_FORMAT
  );

  if (response.endsAt) {
    transformed.endDate = dateTz(
      response.endsAt,
      businessTimezone,
      DATE_FORMAT
    );
    transformed.endTime = dateTz(
      response.endsAt,
      businessTimezone,
      HOUR_FORMAT
    );
  }

  if (!response.endsAt) {
    transformed.endDate = "";
    transformed.endTime = "";
  }

  transformed.items = response.items.map((item) => item.id);
  transformed.usesPerCustomer = response.usesPerCustomer || 0;
  transformed.totalUses = response.totalUses || 0;

  transformed.orderTypes = new Set(response.orderingServices);

  const result = {
    ...response,
    ...transformed,
  };

  return result;
}

export interface IDiscountTransformFetchDiscountResult {
  id: string;
  name: string;
  code: string;
  visibility: DiscountVisibilityEmum;
  type: DiscountTypeEnum;
  amount: number;
  minimumOrderValue: number;
  maximumDiscount: number;
  segmentation: DiscountSegmentationEnum;
  usesPerCustomer: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  totalUses: number;
  orderTypes: Set<OrderingServiceEnum>;
  items: Array<string>;
}
