<template>
  <div class="discount-preview">
    <h5 class="mt-0 mb-4">
      {{ $t("c.discounts.preview") }}
    </h5>
    <div class="discount-preview__card">
      <div class="discount-preview__icon">
        <Icon icon="tag" />
      </div>
      <div class="pl-3">
        <p class="discount-preview__title">
          {{ title }}
        </p>
        <p class="discount-preview__description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@ds";

export default {
  name: "Preview",
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-preview {
  position: sticky;
  top: 26px;
  padding: 30px;
  background: #f5f5f5;

  &__card {
    display: flex;
    align-items: center;
    max-width: 315px;
    padding: 1rem;
    line-height: 1.25;
    border-radius: 1rem;
    background: #fff;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.25rem;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 100%;
    background-color: #e9f0ff;

    svg {
      width: 1rem;
      height: 1rem;
      fill: #1258ff;
    }
  }

  &__title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25;
    margin: 0;
  }

  &__description {
    font-size: 0.75rem;
    color: #8c8c8c;
    line-height: 1.25;
    margin: 0;
  }
}
</style>
